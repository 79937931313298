
	<!-- private _id!: string;
	private _userId!: string;
	private _menuId!: string;
	private _name!: string;
	private _description!: string;
	private _price!: string;
	private _status!: string;
	private _lastUpdate!: string;
	private _image!: string;
	private _displayAsSpecial!: string;
	private _Food = []; -->

<main>
	

	<!-- toast -->
	<div #toastElement 
		id="toastElement" 
		class="toast" 
		role="alert" 
		aria-live="assertive" aria-atomic="true"
	>					
		<div class="toast-header">
			<strong class="toast_text mr-auto">Notification</strong>
		</div>

		<div class="toast-body toast_text"></div>
	</div>

	<!-- create a read-only form for platter data, then toggle to data entry mode -->
	<form #platter_form="ngForm" (ngSubmit)="onSubmit(platter_form)">
		<div class="form-group">
			<label for="name">Name</label>
			<input type="text" class="form-control" id="name" name="name" [(ngModel)]="Platter['name']" ngModel />
		</div>
		<div class="form-group">
			<label for="description">Description</label>
			<input type="text" class="form-control" id="description" name="description" [(ngModel)]="Platter['description']" ngModel />
		</div>
		<div class="form-group">
			<label for="price">Price</label>
			<input type="text" class="form-control" id="price" name="price" [(ngModel)]="Platter['price']" ngModel />
		</div>
		<div class="form-group">
			<label for="status">Status</label>
			<input type="text" class="form-control" id="status" name="status" [(ngModel)]="Platter['status']" ngModel />
		</div>
		<div class="form-group">
			<label for="lastUpdate">Last Update</label>
			<input type="text" class="form-control" id="lastUpdate" name="lastUpdate" [(ngModel)]="Platter['lastUpdate']" ngModel />
		</div>
		<div class="form-group">
			<label for="image">Image</label>
			<input type="text" class="form-control" id="image" name="image" [(ngModel)]="Platter['image']" ngModel />
		</div>
		<div class="form-group">
			<!-- <label for="displayAsSpecial">Display As Special</label> -->
			<input type="checkbox" class="form-control" id="displayAsSpecial" name="displayAsSpecial" [(ngModel)]="Platter['displayAsSpecial']" ngModel />Display As Special
		</div>
		<div class="form-group" text-center>
			<h3 for="Food">Food on this platter/meal</h3>
			<!-- create an unordered list of food items -->
			<ul>
				<li *ngFor="let foodItem of FoodItems">{{ foodItem.name }}</li>
			</ul>
		</div>
		<button type="submit" class="btn btn-success">Submit</button>
	</form>
</main>