<div class="body">

	<!-- main element for Google's javascript validations. -->
	<main>

		<div class="quickstart_body">

			<div id="qs_verbage" class="row">
				<div class="col-12">
					<h4>Email Addresses</h4>
					Add new email addresses to your kitchen.
				</div>
			</div>


			<div class="qs_outer_interface row">

				<div id="qs_inner_interface" class="col-12">

					<button class="btn btn-success circleButton" 
						routerLink="/addEmailAddress">
						<!-- <fa-icon
							[icon]="faPlus">
						</fa-icon> -->
					</button>



					<!-- toast -->
					<div #toastElement 
						id="toastElement" 
						class="toast" 
						role="alert" 
						aria-live="assertive" aria-atomic="true"
					>					
						<div class="toast-header">
							<strong class="toast_text mr-auto">Notification</strong>
						</div>
				
						<div class="toast-body toast_text"></div>
					</div>


				<!-- Material form contact | Material form contact -->
					<div id="emailsList" class="card">

						<div><p>Add email addresses.</p></div>


						<!--Card content-->
						<div class="card-body px-lg-5 pt-0">
							<ul class="list-group list-group-flush">
								<app-email-list-item 
									*ngFor="let email of Emails"
									[email]="email.email"
									[emailType]="email.emailType"
									(onDelete)="removeEmail($event)"
								></app-email-list-item>
							</ul>
						</div>

					</div>
					<!-- Material form contact | Material form contact -->

				</div>

			</div>

		</div>
		<!-- EMAILS EMAILS EMAILS -->

	</main>

</div>