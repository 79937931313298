<!-- main element for Google's javascript validations. -->
<main>
	<!-- Container for the demo -->
	<div class="list-group">
		<div
			*ngFor="let menuItem of Menus"
			(click)="manage(menuItem.url);"
			class="{{ menuItem.classes }}  box_shadow"
			[ngStyle]="{
				'border-bottom':'1px solid #ffffff',
				'font-size':'x-large', 
				'margin-bottom': '.5rem', 
				'border-radius': '1rem'}"
		>

			<div id="{{ menuItem.url }}">
				<div class="card-icon" style="padding-top: 1rem; width: 50px;">
					<span>
						<i class="{{ menuItem.icon }}" aria-hidden="true" style="color: #19aa8d; font-size: xx-large"></i>
					</span>
				</div>
				<div style="margin-left: 1rem;">
					<span class="list-group-item-heading">
						{{ menuItem.heading }}
					</span>
					<p class="list-group-item-text">{{ menuItem.text }}</p>
				</div>
			</div>

		</div>

	</div>
</main>