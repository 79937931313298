<app-header #appHeader
	[title]="title"
	[menuItems]="menuItems"
	(onTitleChange)="onTitleChange($event)"
	(onMenuItemChange)="onMenuNavigate($event)">
</app-header>

<div id="spacer_header" #header_spacer></div>

<router-outlet 
	(activate)='onActivate($event)'
></router-outlet>

<div id="spacer_footer" #footer_spacer></div>

<app-footer #appFooter></app-footer>