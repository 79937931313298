<!-- toast -->
<div #toastElement 
	id="toastElement" 
	class="toast" 
	role="alert" 
	aria-live="assertive" aria-atomic="true"
>					
	<div class="toast-header">
		<strong class="toast_text mr-auto">Notification</strong>
	</div>

	<div class="toast-body toast_text"></div>
</div>


<!-- Add Category Button -->
<div id="categoryList" style="padding: 1.5rem; margin-top: 2rem;">
	<ul class="list-group">
		<li 
			class="list-group-item list-group-item-success 
				d-flex 
				justify-content-between 
				align-items-center" 
			*ngFor="let foodType of FoodTypeList" 
			style="border-radius: 25px;
				background-color: white;
				border: 1px solid forestgreen;
				margin-bottom: 2rem;
				color: black;
				text-align: left;"
		>
			<div style="width: 10%;">
				<div 
					id="onDelete"
					aria-label="add food item to platter" 
					ng-click="openRemoveCategoryDialog($index);"
				>
					<i 
						class="fas fa-times" 
						style="color: grey; font-size: x-large;"></i>
				</div>	
			</div>

			<!-- <div style="display: inline-flex; width: 85%;" >
				<a [routerLink]="['foodDetailPage/', foodType.type]">{{ foodType.type }}</a>
				<div style="width: 10%;">
					<i style="color: green; font-size: x-large;" class="fas fa-chevron-right"></i>
				</div>
			</div> -->
			<div style="display: inline-flex; width: 85%;" >
				<a (click)="showFoodPage(foodType)">{{ foodType.type }}</a>
				<div style="width: 10%;">
					<i style="color: green; font-size: x-large;" class="fas fa-chevron-right"></i>
				</div>
			</div>
		</li>
	</ul>					
</div>