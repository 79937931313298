<main>

	<!-- toast -->
	<div #toastElement 
		id="toastElement" 
		class="toast" 
		role="alert" 
		aria-live="assertive" aria-atomic="true"
	>					
		<div class="toast-header">
			<strong class="toast_text mr-auto">Notification</strong>
		</div>

		<div class="toast-body toast_text"></div>
	</div>
	<ul class="list-group text-left" style="color: black;">
		<li class="list-group-item listitem"  *ngFor="let platter of Platters">
			<!-- <fa-icon 
				[icon]="faTimes" 
				aria-label="delete platter" 
				(click)="onDelete(platter)"
				style="color: red;"
			></fa-icon> -->

			<div (click)="showPlatter(platter);">{{ platter.name }}</div>

			<!-- <fa-icon 
				[icon]="faChevronRight" 
				aria-label="go to platter detail page"
				(click)="showPlatter(platter);"
			></fa-icon> -->
		</li>
	</ul>
</main>