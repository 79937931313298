<!-- main element for Google's javascript validations. -->
<main>
	<form 
		class="form mt-4" 
		[ngStyle]="{'text-align':'-webkit-center'}" 
		(ngSubmit)="submit()">
		<div style="color: black;">
			<h2>Edit Food Item</h2>
			<p>Make changes to your food item.</p>
		</div>
		<hr />
		
	<!-- toast -->
		<div #toastElement 
		id="toastElement" 
		class="toast" 
		role="alert" 
		aria-live="assertive" 
		aria-atomic="true"
		style="
			position: relative; 
			top: 50%; 
			left: 50%; 
			transform: translate(-50%, -50%);"
		>

		<div class="toast-header">
		<strong class="toast_text mr-auto">Notification</strong>
		</div>

		<div class="toast-body toast_text"></div>
		</div>
		<hr />
		<!-- Food Item Name -->
		<div class="form-group">
			<label for="name">Food Item Name</label>
			<div class="input-group">
				<input type="text"
					id="name" 
					name="name"
					class="form-control"
					placeholder="Name..."
					[(ngModel)]="name"
					required
				>
			</div>
		</div>

		<!-- Food Item Type -->
		<div class="form-group">
			<label for="type">Food Item Type</label>
			<div class="input-group">
				<select id="type" name="type" class="form-control"
					(change)="onChange($event)"
					[(ngModel)]="type"
					required
				>
					<option value="">{{ type }}</option>
					<option 
						*ngFor="let type of FoodTypeList" 
						[value]="type.type"
					>
						{{type.type}}
					</option>
				</select>
			</div>
		</div>

		<!-- Food Item Description -->
		<div class="form-group">
			<label for="description">Food Item Description</label>
			<div class="input-group">
				<textarea 
					id="description" name="description" 
					class="form-control" 
					rows="3" 
					placeholder="Description..."
					[(ngModel)]="description"
				></textarea>
			</div>
		</div>

		<!-- Food Item Price -->
		<div class="form-group">
			<label for="price">Food Item Price</label>
			<div class="input-group">
				<input type="number"
					id="price" 
					name="price"
					class="form-control"
					placeholder="Price..."
					[(ngModel)]="price"
					required
				>
			</div>
		</div>

		<!-- Food Item Image -->
		<div class="form-group">
			<label for="image">Food Item Image</label>
			<div class="input-group">
				<input type="text"
					id="image" 
					name="image"
					class="form-control"
					placeholder="Image..."
					[(ngModel)]="image"
				>
			</div>
		</div>

		<!-- Food Item Status -->
		<div class="form-group">
			<label for="status">Food Item Status</label>
			<div class="input-group">
				<!-- make a label for the status checkbox -->
				<label class="switch">
					<input type="checkbox" 
						id="status" 
						name="status"
						[(ngModel)]="status"
					>
					<span class="slider round"></span>
				</label>
			</div>
		</div>
		<!-- Submit Button -->
		<div class="form-group">
			<input type="submit" class="btn btn-success" value="Submit" />
		</div>

	</form>
</main><!-- end of main -->