<!-- body -->
<div class="body">

	<div class="container">
		<div class="row">
			<div class="col-12">

				<!-- toast -->
				<div #toastElement 
					id="toastElement" 
					class="toast" 
					role="alert" 
					aria-live="assertive" aria-atomic="true"
				>					
					<div class="toast-header">
						<strong class="toast_text mr-auto">Notification</strong>
					</div>
			
					<div class="toast-body toast_text"></div>
				</div>


				<form #formData
					class="form mt-4 formGroup box_shadow" 
					[formGroup]="form" 
					[ngStyle]="{'text-align':'-webkit-center'}" 
					(ngSubmit)="onSubmit(formData)"
					style="border: 1px solid #ccc; border-radius: 5px; padding: 16px;"
				>
					<!-- icon -->
					<div class="mb-4 text-center">
						<!-- <fa-icon 
							id="showPasswordEye" 
							class="success" 
							[ngStyle]="{'color':'green','font-size':'68px'}"
							[icon]="faUser">
						</fa-icon> -->
					</div>


					<h3 class="mb-4">Sign In to Your Account</h3>


					<!-- userName - input type=email -->
					<div class="form-group mb-4">
						<input #userName
							id="email"
							type="email"
							name="email"
							formControlName="email"
							class="form-control"
							placeholder="Email" 
							autocomplete="username"
						/>
					</div>
					<div *ngIf="form.get('email')!.invalid && form.get('email')!touched">
						<div *ngIf="form.get('email')!.errors!required">
							Email is required.
						</div>
						<div *ngIf="form.get('email')!.errors!email">
							Please enter a valid email.
						</div>
					</div>


					<!-- userPassword - input type=password -->
					<div class="form-group mb-4">
						<input #userPassword
							id="password" 
							type="password" 
							name="password"  
							formControlName="password"
							class="form-control" 
							placeholder="Password"
							autocomplete="current-password" 
						/>
					</div>
					<div *ngIf="form.get('password')!.invalid && form.get('password')!.touched">
						<div *ngIf="form.get('password')!.errors!required">
							Password is required.
						</div>
						<div *ngIf="form.get('password')!.errors!minlength">
							Password must be at least 8 characters long.
						</div>
						<div *ngIf="form.get('password')!.errors!maxlength">
							Password must be no more than 20 characters long.
						</div>
						<div *ngIf="form.get('password')!.errors!pattern">
							Password must contain at least one lowercase, one uppercase, one number, and one special character.
						</div>
					</div>



					<!-- rememberMe Checkbox - input type=checkbox
					<div class="form-group">
						<label class="checkbox-inline"><input type="checkbox"> Remember me</label>
					</div> -->



					<!-- Calling SignIn Api from AuthService -->
					<div class="form-group mb-4">
						<button type="submit" 
							class="button btn btn-success mpkButton btn-block btn-lg mb-4"
							id="quickstart-sign-in" 
							[disabled]="form.invalid"
						>
							Sign In
						</button>
					</div>
				</form>


				


				<div class="text-center box_shadow"
					style="border: 1px solid #ccc; border-radius: 5px; padding: 16px;">

					<!-- Calling GoogleAuth Api from AuthService -->
					<button 
						type="button" 
						class="btn btn-success" 
						(click)="googleLogin()"
					>
						<i class="fab fa-google-plus-g"></i>
							Log in with Google
					</button>
					<br /><br />

					<button 
						class="btn btn-success" 
						(click)="openResetPasswordDialogBox()">
						Reset Password
					</button>
					<br /><br />

					<button 
						class="btn btn-success" 
						(click)="goToForgotPassword()"
					>Forgot Password?</button>
					<br /><br />
					
					<span>Don't have an account?</span>
					<button 
						class="btn btn-success" 
						(click)="goToSignUp()"
					>Sign Up</button>
					<br /><br />
				</div>
				Status: <span id="quickstart-sign-in-status">{{ status }}</span>






				<!-- The Modal -->
				<div class="modal" id="resetPasswordDialogBox">
					<div class="modal-dialog modal-sm">
						<div class="modal-content">

							<!-- Modal Header -->
							<div class="modal-header">
								<h4 class="modal-title">Reset Password</h4>
								<button 
									type="button" 
									class="btn-close" 
									(click)="closePasswordDialogBox()">
								</button>
							</div>


							<!-- Modal body -->
							<div class="modal-body">
								<label for="resetWithEmailAddress">Email Address</label>
								<input #resetEmail 
									id="resetWithEmailAddress" 
									type="email" 
									class="form-control" />
							</div>


							<!-- Modal footer -->
							<div class="modal-footer">
								<button type="button" class="btn btn-success" 
									(click)="sendPasswordReset(resetEmail)">
									Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 


	<button 
		(click)="signOut()" 
		*ngIf="userData"
	>
		Logout
	</button>
</div>